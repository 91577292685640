<template>
  <div class="Accept p-t-xl">
    <transition name="fade" mode="out-in">
      <div v-if="!isFetched" class="Accept__content-loading">
        <div class="m-b-xl">
          <heading size="3">
            {{ $t('pages.accept.fetching_invitation') }}
          </heading>
        </div>
        <div v-loading="isFetching" class="loading" />
      </div>
      <div v-else class="Accept__content">
        <heading size="3">
          {{ $t('general.hello') }}
          <span v-if="inviteData.invitee && inviteData.invitee.first_name" data-testid="inviteeData">{{ inviteData.invitee.first_name }} {{ inviteData.invitee.last_name }}</span>
        </heading>
        <i18n
          v-if="inviteData.inviter"
          path="pages.accept.invited_to_be_member_of_brand"
          tag="div"
          class="Accept__details is-size-5 m-b-xl"
        >
          <span slot="user" class="has-text-weight-semibold">
            {{ inviteData.inviter.first_name }} {{ inviteData.inviter.last_name }}
          </span>
          <span slot="brand" class="has-text-weight-bold has-text-primary">{{ inviteData.brand.name }}</span>
        </i18n>
        <button
          :class="{'is-loading': isLoading}"
          class="button is-primary is-large"
          data-testid="activateUserTrigger"
          @click="activateUser"
        >
          {{ $t('pages.accept.accept_invitation') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { BrandApiService } from '@hypefactors/shared/js/services/api/BrandApiService'

export default {
  data () {
    return {
      isFetched: false,
      isLoading: false,
      isFetching: false,
      brandId: '',
      invitationId: '',
      inviteData: {
        inviter: null,
        invitee: null,
        brand: {}
      }
    }
  },
  computed: {
    ...mapGetters(['signedIn', 'currentUser']),
    isCurrentUserTheInvited () {
      return this.currentUser.email === this.inviteData.invitee.email
    }
  },
  beforeRouteEnter /* istanbul ignore next */ (to, from, next) {
    if (!to.query.id && !to.query.brand) return next('/')
    // move this here, so we dont fetch on re-mount
    next((vm) => {
      vm.cacheQueryParams(to.query)
      vm.fetchInvitationData()
    })
  },
  methods: {
    ...mapActions(['syncUpGlobalAuth', 'logoutGlobally']),
    cacheQueryParams (query) {
      this.brandId = query.brand
      this.invitationId = query.id
    },
    fetchInvitationData () {
      this.isFetching = true
      const payload = {
        params: {
          include: ['inviter', 'invitee', 'brand']
        }
      }
      return BrandApiService.fetchInvitation(this.brandId, this.invitationId, payload)
        .then(data => {
          this.isFetching = false
          this.inviteData = { ...data }
          this.inviteData.invitee = data.invitee.data
          this.inviteData.inviter = data.inviter.data
          this.inviteData.brand = data.brand.data
          this.isFetched = true
          if (data.accepted) {
            this.$notify.warning({
              message: this.$t('warnings.invitation_already_accepted'),
              duration: 5000
            })
            return this.leaveAcceptPage()
          }
        })
        .catch(error => {
          this.isFetching = false
          this.$displayRequestError(error, this.$t('errors.cannot_activate_user'))
          this.leaveAcceptPage()
        })
    },
    activateUser () {
      this.isLoading = true
      return BrandApiService.acceptInvitation(this.brandId, this.invitationId)
        .then(response => {
          this.handleResponse(response)
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.cannot_activate_user'))
        })
    },
    async handleResponse (data) {
      if (this.isCurrentUserTheInvited) {
        this.$notify.success(this.$t('success.invited_to_brand', { brand: this.inviteData.brand.name }))
        return this.$router.push({ name: 'dashboard.dashboard' })
      } else if (this.signedIn) {
        await this.logoutGlobally()
      }
      return this.loginInvitedUser(data)
    },

    async loginInvitedUser (data) {
      try {
        await this.syncUpGlobalAuth({
          accessToken: data.access_token,
          refreshToken: '' // we dont have it at this endpoint
        })
        this.$notify.success(this.$t('success.invited_to_brand', { brand: this.inviteData.brand.name }))
        this.$router.push({ name: 'settings.personal.preferences' })
      } catch (err) {
        this.$handleError(err, {
          reason: 'Could not store auth token on user invitation to brand',
          inviteData: this.inviteData
        })
        this.$notify.error(this.$t('errors.cannot_login_invited_user_use_reset_password'))
        this.$router.push({ name: 'password-recovery' })
      }
    },

    async leaveAcceptPage () {
      // if not logged in
      if (!this.signedIn) return this.$router.push({ name: 'login' })
      // if logged in with the same account
      if (this.inviteData.invitee && this.isCurrentUserTheInvited) {
        this.$router.push({ name: 'dashboard.dashboard' })
      } else {
        // if logged in but with another account
        await this.logoutGlobally()
        this.$router.push({ name: 'login' })
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.Accept {
  min-height: 100vh;
  display: flex;

  &__content {
    width: 100%;
    text-align: center;
  }

  &__content-loading {
    width: 100%;
    text-align: center;

    .loading {
      position: relative;
    }
  }
}
</style>
